import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import DialogComponent from '@Components/Common/DialogComponent';
import AccountTypeLogin, { TextH4, TextH2, Button, LoginSectionContainer, TextSmallBold, RefferalContainer, CurrentRefferal, PromoterPackInViewCartPop } from './ViewCartPop.style';
import api from '@api/Apis';
import { useRouter } from 'next/router';
import PackProduct from '@PagesComponent/PromoterPack/PackProduct';
var randomstring = require("randomstring");

const RegisterSection = ({ data: { heading, subheading, setDesignation, url, setRefferalPopup } }) => (

    <div className="ic-pc-container">
        <TextH2 className="heading">{heading}</TextH2>
        {subheading !== "register" &&
            <TextSmallBold className="subtitle">Register as a {subheading}</TextSmallBold>
        }
        <div className="ic-pc-button-label">
            {url ?
                <Button as="a" href={`/us/${url}`} className="btn-arbonne-primary" >Register</Button>
                :
                <Button className="btn-arbonne-primary" onClick={() => {
                    setRefferalPopup(true);
                    setDesignation(subheading);
                }}>Register</Button>
            }
        </div>
    </div>
);

RegisterSection.propTypes = {
    data: PropTypes.object.isRequired
}


const ViewCartPopup = React.forwardRef(({ content }, ref) => {
    const router = useRouter();
    const { open, setOpen, setshowminicart, setshowloader, isLoginToken } = content;

    const [refferalPopup, setRefferalPopup] = React.useState(false);
    const [designation, setDesignation] = React.useState('');
    const [referrerInfo, setReferrerInfo] = useState(false);
    const [refferalCode, setRefferalCode] = React.useState({
        value: "",
        error: ""
    });

    const [goToPromoterPack, setGoToPromoterPack] = React.useState(false);
    const [correctRefferal, setCorrectRefferal] = React.useState({
        status: false,
        data: {}
    });

    React.useImperativeHandle(ref, () => ({
        setRefferalPopup: (value) => setRefferalPopup(value),
        setGoToPromoterPack: (value) => setGoToPromoterPack(value),
        setCorrectRefferal: (value) => setCorrectRefferal(value)
    }));

    const handleSubmit = async () => {
        if (refferalCode?.value) {
            await api.subDomainCheckFunction({ referral_code: refferalCode?.value }).then((res) => {
                if (res?.status === 200) {
                    if (res?.data?.status === true) {
                        const { user_details } = res?.data?.data;
                        setCorrectRefferal({
                            status: res?.data?.status,
                            data: {
                                image: user_details?.image,
                                name: user_details?.name,
                                email: user_details?.email,
                                phone: user_details?.phone
                            }
                        });
                        setGoToPromoterPack(false);
                    } else {
                        setRefferalCode({ ...refferalCode, error: 'This Referrer Code is invalid. Please check with your Referrer and try again.', });
                        setCorrectRefferal({ status: res?.data?.status, data: {} });
                    }
                }
            }).catch((err) => console.log(err))
        } else {
            setRefferalCode({ ...refferalCode, error: 'please enter refferal code', })
        }
    }

    return (
        <>
            <DialogComponent opend={open} handleClose={setOpen} title={"LIVE BETTER, LONGER"} titleBanner={<img src="/images/Shop_Kaire.png" />} classFor="view_cart_popup">
                {!(refferalPopup) &&
                    <AccountTypeLogin className="account-type-login">
                        <div className="login-section">
                            <TextH4> Already Registered?</TextH4>
                            <div className="login-section-button">
                                <Button as="a" href="/us/login" section="login" className="btn-arbonne-primary">Login</Button>
                            </div>
                        </div>
                        <div className="login-section-divider "></div>
                        <div className="account-creation-section">
                            <TextH4 section="create">Create an Account</TextH4>
                            <LoginSectionContainer>
                                {/* <RegisterSection data={{
                           heading: "Register",
                           setDesignation,
                           subheading: "distributor",
                           url: "",
                           setRefferalPopup
                       }} />*/}
                                <RegisterSection data={{
                                    heading: "Distributor",
                                    setDesignation,
                                    subheading: "member",
                                    url: "signup/?d=member",
                                    setRefferalPopup
                                }} />
                                <RegisterSection data={{
                                    heading: "Customer",
                                    setDesignation,
                                    subheading: "customer",
                                    url: "",
                                    setRefferalPopup
                                }} />
                            </LoginSectionContainer>
                        </div>
                    </AccountTypeLogin>
                }
                {(refferalPopup && !correctRefferal?.status) &&
                    <RefferalContainer className="">
                        <h3 className="ref_title"> <img src='/images/welcome.png' /></h3>
                        <h4 className="ref_subtitle">Please enter your referring member's Referrer Code:</h4>
                        <div className="text-container">
                            <div className="ref_input-form">
                                <input type="text" className="form-control"
                                    name="referring_id"
                                    placeholder="Referrer code"
                                    onChange={(e) => {
                                        setRefferalCode({ value: e.target.value, error: "" });
                                    }} />
                            </div>
                            <div className="sk-ref__input-btn">
                                <Button type="button" className="ref_input-button" onClick={() => handleSubmit()}>Submit</Button>
                            </div>
                        </div>

                        <p className="dont_haveCode" onClick={() => {
                            if (designation === "member") {
                                setGoToPromoterPack(true);
                                setRefferalPopup(true);
                                setCorrectRefferal({ status: true, data: [] });
                                // localStorage.removeItem("correctRefferal")
                                // localStorage.removeItem("referral_code")
                            } else {
                                // window.location.assign('/us/signup?d=member');
                                if ((isLoginToken)) {
                                    window.location.assign(`https://sharekaire.com/signup/?token=${isLoginToken}`)
                                }
                                else {
                                    window.location.assign('https://sharekaire.com/signup')
                                }
                                // localStorage.removeItem("correctRefferal")
                                // localStorage.removeItem("referral_code")
                            }
                        }}>I don't have referrer code</p>

                        <p className="ref_msg_new"><span style={{ cursor: "pointer", color: "#23527c" }} onClick={() => setReferrerInfo(true)}>Not sure what this means?</span> Send an email with subject line 'Referrer Code' to <a className="ref__mailto" href="mailto:support@kaire.com">support@kaire.com</a></p>
                        <p className="error_msg">{refferalCode?.error ? refferalCode?.error : ""}</p>
                    </RefferalContainer>
                }

                {(refferalPopup && correctRefferal?.status && !goToPromoterPack) &&
                    <CurrentRefferal className="currentRef_body">
                        <h5 className="refby-title">You were referred to Kaire by:</h5>
                        <img src={`${process.env.API_URL}${correctRefferal?.data?.image}?id=${randomstring.generate(16)}`} width='60' height='60' />
                        <h4 className="refby-name">{correctRefferal?.data?.name}</h4>
                        <div className="refby-contact">
                            <a href={`tel:${correctRefferal?.data?.phone}`} className="refby-contact-info ">{correctRefferal?.data?.phone}</a>
                            <a href={`mailto:${correctRefferal?.data?.email}`} className="refby-contact-info">{correctRefferal?.data?.email}</a>
                        </div>
                        <div className="refby-btn">
                            <Button className="refby_button" onClick={() => {
                                if (designation === "member") {
                                    setGoToPromoterPack(true);
                                    localStorage.setItem("referral_code", refferalCode?.value);
                                    localStorage.setItem('correctRefferal', correctRefferal);
                                } else {
                                    localStorage.setItem("referral_code", refferalCode?.value);
                                    localStorage.setItem('correctRefferal', JSON.stringify(correctRefferal));
                                    // window.location.assign('/us/signup?d=member');
                                    if (isLoginToken) {
                                        window.location.assign(`https://${refferalCode?.value}.sharekaire.com/signup/?token=${isLoginToken}`)
                                        // window.location.assign(`http://${refferalCode?.value}.localhost:5173/signup/?token=${token}`)
                                    }
                                    else {
                                        window.location.assign(`https://${refferalCode?.value}.sharekaire.com/signup/`)
                                    }

                                }
                            }}>Yes, This is my referrer</Button>
                        </div>
                        <button className="ref-confirm-no" onClick={() => setCorrectRefferal({ ...correctRefferal, status: false })}>
                            No, this is not my referrer
                        </button>
                        <p className="refby_note">Please note, your referrer cannot be changed after your account is created.</p>
                    </CurrentRefferal>
                }

                {(refferalPopup && goToPromoterPack && correctRefferal?.status) &&
                    <PromoterPackInViewCartPop>
                        <h3>One Promoter pack is required for become a Promoter</h3>
                        <PackProduct
                            setshowminicart={setshowminicart}
                            setshowloader={setshowloader}
                            section={content?.section}
                            signUpCredential={content?.credential}
                            LoginSubmit={(data) => content?.LoginSubmit(data)}
                            partOf="MEMBERSHIP" />
                    </PromoterPackInViewCartPop>
                }
            </DialogComponent>

            <DialogComponent opend={referrerInfo} handleClose={setReferrerInfo} classFor="referral_code">
                <div className='referrer-info-data'>
                    <p className='refer-text-data'>What is a Referral Code?</p>
                    <p> A Referral Code is a personal name or a mixture of letters and numbers that is easily remembered. You would give this code to the person
                        that you are referring to your products and/or business in order for the company to track who you have
                        referred as a customer or a member.
                    </p>
                </div>
                <div className='refer-url'>
                    <p className='refer-text-data'>What is a Referral URL Link?</p>
                    <p>A Referral Code can be combined with a website or webpage as a Referral URL Link.
                        For example: <a href='code.shopkaire.com' target='_blank'>code.shopkaire.com</a> <a href='http://code.shopkaire.com' target='_blank'>(http://code.shopkaire.com/)</a> or <a href='code.sharekaire.com' target='_blank'>code.sharekaire.com</a>
                        <a href='http://code.sharekaire.com' target='_blank'> (http://code.sharekaire.com/)</a> and sent
                        to your potential referred customer and/or member to be linked to you.</p>
                </div>
            </DialogComponent>
        </>
    );
});

ViewCartPopup.propTypes = {
    content: PropTypes.object.isRequired,
}
export default ViewCartPopup;


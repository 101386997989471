import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useState } from "react";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styled from "styled-components";

const DialogStyle = styled.div`
  & h2 {
    &.dialog_title_for_all { 
    background: #06356a;
    color: #fff;
    font-size: 20px;
    padding: 15px 24px;
    text-transform: uppercase;
    z-index: 99;
    position: relative;
    text-align: left;
    justify-content: center;
    margin-bottom: 0;
    font-family: var(--common-font);
    display: flex;
    align-items: flex-end;
    gap: 30px;
    font-weight: 500; 
      @media (max-width: 767px) {
        font-size: 14px;
        gap: 20px;
        img{
          max-width: 100px;
        }
      }
      @media (max-width: 576px){
      font-size: 13px;
      gap: 10px;
      padding: 10px;
      }
        .close_button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      svg {
        font-size: 30px;
        @media (max-width: 767px) {
          font-size: 25px;
        }
      }
      @media (max-width: 767px) {
        right: 0;
      }
    }
    }
  }


  .ViewCartPopstyle__PromoterPackInViewCartPop-sc-19t1cmq-8 > h3 {
    margin: 0 0 20px;
    text-align: center;
  }

  input {
    &[type="text"] {
      margin-bottom: 20px;
      width: 100%;
      border: 1px solid #ddd;
      padding: 7px;
      border-radius: 3px;
    }
  }

  .close_button {
    color: #fff;
    position: absolute;
    background: #06356a;
    right: 24px;
    top: 9px;
    z-index: 9999;
    @media (max-width: 767px) {
      right: 0;
      top: 0;
    }
  }
  .add_address_class_from_autoship_main_dialog,
  .add_address_from_CheckoutPage_main_dialog {
    /* min-width: 695px !important; */
    .col-md-12 {
      padding: 0px;
    }
    .col-md-6 {
      padding: 10px;
      &:first-child {
        padding-right: 10px;
      }
      &:last-child {
        padding-left: 10px;
      }
      @media (max-width: 991px) {
        &:first-child {
          padding-right: 0px;
        }
        &:last-child {
          padding-left: 0px;
        }
      }
    }

    select {
      margin-bottom: 20px;
      width: 100%;
      border: 1px solid #ddd;
      padding: 7px;
      border-radius: 3px;
      height: 40px;
      background: none;
    }
    .select {
      &:after {
        top: 50%;
      }
    }
    textarea {
      margin-bottom: 20px;
      width: 100%;
      border: 1px solid #ddd;
      padding: 7px;
      border-radius: 3px;
      height: 120px;
    }
    input {
      border: 1px solid #ddd;
      height: 40px;
      box-shadow: none;
    }
    p {
      font-size: 14px;
      line-height: 21px;
      margin-left: 10px;
    }
    button {
      padding: 10px 20px;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 45px;
      margin: 9px 0 0;
      border: 2px solid #06356a;
      margin-top: 0;
      max-width: 150px;
      &:hover {
        background: none;
        color: #06356a;
      }
    }
    h2 {
      button {
        border: none;
        top: 5px;

        &:hover {
          color: #fff;
        }
      }
      [aria-label="close"] {
        top: 30px;
        @media (max-width: 767px) {
          top: 15px;
        }
      }
    }
    .cstm-btns-sec {
      margin-left: -15px;
    }
  }
 
  .paymentform_from_savedCardComp_main_dialog {
    select {
      margin-bottom: 20px;
      width: 100%;
      border: 1px solid #ddd;
      padding: 7px;
      border-radius: 3px;
      background: none;
      height: 40px;
    }
    .select.select select {
      margin-bottom: 0;
    }

    .select {
      margin-bottom: 10px;
    }

    input {
      &[type="text"] {
        margin-bottom: 20px;
        height: 40px;
        box-shadow: none;
      }
    }
  }
  .change_passsword_from_profile_main_dialog {
    .form-control {
      border: 1px solid #ddd;
      box-shadow: none;
      height: 40px;
    }
    button {
      margin-left: 17px;
      background: #06356a;
      border-radius: 25px;
      border: 2px solid #06356a;
      padding: 6px 10px;
      height: 40px;
      font-weight: 600;
      text-transform: uppercase;
      &:hover {
        background: none;
        color: #06356a;
      }
    }
    h2 {
      button {
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .product_add_from_autoship_main_dialog {
    width: 100%;

    h2#alert-dialog-title {
      height: 50px;
    }
    input.search {
      width: 100% !important;
    }
    .message {
      padding: 0 10px;
    }
    .visible.menu.transition {
      padding: 10px 0 !important;
    }
  }

  @media (max-width: 767px) {
    .add_address_class_from_autoship_main_dialog,
    .add_address_from_CheckoutPage_main_dialog {
      min-width: 100% !important;
    }
    /* .product_add_from_autoship_main_dialog {
        width: 100%;
    } */
    .multiple.search.selection.dropdown {
      padding: 0 15px;
    }
  }

  @media (max-width: 480px) {
    .MuiModal-root.MuiDialog-root.product_add_from_autoship.css-zw3mfo-MuiModal-root-MuiDialog-root {
      padding: 0 10px;
    }
    .payment_fields .row {
      margin: 0 !important;
    }
    .payment_fields .action_button {
      margin: 0 18px;
    }
  }

  /* 26-07-2022 */
  .order_details_main_dialog {
    h2 {
      margin-bottom: 0;
      justify-content:center;
    }
    .address_ship {
      background: #f1f1f1;
      padding: 10px;
      border-radius: 0px;
      margin-bottom: 15px;
      h3 {
        margin: 0 0 10px;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .MuiDialogTitle-root + .css-ypiqx9-MuiDialogContent-root {
      padding-top: 20px;
     }

    h3 {
      text-transform: uppercase;
    }
    .billing_details {
      border-right: 1px dashed #a0a0a0;
      /* margin-right: 10px; */
    }
  }
  .promoter_pop_up_main_dialog{
    background: #00356a;
       text-align: center;
        color: #fff;
        h2{ 
          justify-content: center;
        }
}
.col-md-12.company_space{
    padding-left: 1opx;
    padding-right: 10px;    
}
.view_cart_popup_main_dialog img {
    width: 100%;
    height: 100%;
    max-width: 200px;
}
`;
export default function DialogComponent({
  children,
  opend,
  handleClose,
  titleBanner,
  title,
  classFor,
}) {
  const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle className="dialog_title_for_all" {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            className="close_button"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };
  return (
    <Dialog
      open={opend}
      className=""
      classes={{ paper: classFor, scrollPaper: "dialog_scrollClass" }}
    >
      <DialogStyle>
        <div className={`${classFor}_main_dialog`}>
          <DialogTitle
            id="alert-dialog-title"
            onClose={() => handleClose(false)}
          >
            {titleBanner ? titleBanner : ""}
            {title ? title : ""}
          </DialogTitle>
          <DialogContent className="container_for_load_data">
            {children}
          </DialogContent>
        </div>
      </DialogStyle>
    </Dialog>
  );
}
